import type { ActionFunctionArgs, LoaderFunctionArgs } from '@remix-run/node';
import { Form, json, redirect, useActionData, useNavigation, useSearchParams } from '@remix-run/react';
import { useState, useEffect } from 'react';
import { z } from 'zod';

import { ApiError } from '~/api/ApiError';
import { Link } from '~/components/Link';
import { getAuthSession } from '~/features/auth/.server/session/getAuthSession';
import { refreshAuthSession } from '~/features/auth/.server/session/refreshAuthSession';
import { sendMagicLink } from '~/features/auth/api/sendMagicLink';
import { getUserHomePage } from '~/features/auth/utils/getUserHomePage';
import { getFormProps, getInputProps, parseSubmission, useConform } from '~/hooks/useConform';

import logo from '../assets/new-ramdam-logo.svg';
import { Alert } from '../components/Alert';
import { Button } from '../components/Button';
import { Control } from '../components/forms/Control';
import { Input } from '../components/forms/Input';
import { ProfileType } from '../features/users/profileType';

const schema = z.object({
  email: z
    .string({
      required_error: 'Please enter a valid email address',
    })
    .email(),
  offer: z
    .string()
    .uuid('The offer has an incorrect format. Are you sure you correctly copied the page address?')
    .optional(),
});

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const authSession = await getAuthSession(request);

  if (authSession.isAuthenticated) {
    const headers = await refreshAuthSession(authSession);
    return redirect(getUserHomePage(authSession.data.profileType), { headers });
  }

  return null;
};

export const action = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const submission = parseSubmission(formData, { schema });

  if (submission.status !== 'success') {
    return json(submission.reply(), 400);
  }

  try {
    await sendMagicLink({
      email: submission.value.email,
      profileType: ProfileType.Client,
      origin: new URL(request.url).origin,
      offer: submission.value.offer,
    });
    return json(submission.reply());
  } catch (err) {
    // Avoid leaking whether an user with this email exists in DB
    if (err instanceof ApiError && err.status === 401) {
      return json(submission.reply());
    }

    return json(
      submission.reply({
        formErrors: ['Something went wrong. Please try again later.'],
      }),
      err instanceof ApiError && err.status ? err.status : 500,
    );
  }
};

export default function AuthLoginRoute() {
  const [searchParams] = useSearchParams();
  const navigation = useNavigation();
  const lastResult = useActionData<typeof action>();
  const alertMessage = searchParams.get('error');

  const { form, fields } = useConform({
    schema,
    lastResult,
    defaultValue: {
      email: searchParams.get('email'),
      offer: searchParams.get('offer'),
    },
  });

  const isLoading = navigation.state === 'submitting';

  const [cooldown, setCooldown] = useState(0);
  const [showEmailSent, setShowEmailSent] = useState(false);

  useEffect(() => {
    if (cooldown <= 0) {
      return;
    }
    const timer = setInterval(() => {
      setCooldown((prevCooldown) => prevCooldown - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [cooldown]);

  useEffect(() => {
    if (form.status === 'success') {
      setCooldown(50);
      setShowEmailSent(true);
    }
  }, [form.status]);

  const handleResend = async () => {
    setCooldown(50);
    try {
      const email = fields.email.value;
      if (!email) {
        throw new Error('Email is required');
      }
      await sendMagicLink({
        email,
        profileType: ProfileType.Client,
        origin: window.location.origin,
        offer: fields.offer.value || undefined,
      });
    } catch (error) {
      console.error('Failed to resend magic link:', error);
      setCooldown(0);
    }
  };

  const handleGoBack = () => {
    setShowEmailSent(false);
  };

  return (
    <main className="flex min-h-screen flex-col items-center bg-gray-50">
      <header className="relative w-full px-11 py-6">
        <div className="flex sm:justify-center">
          <a href="https://ramd.am">
            <img src={logo} alt="Ramdam" className="h-8" />
          </a>

          <Link
            to="/client/signup"
            variant="btn-registration-outline"
            size="xl"
            className="absolute right-4 top-4 !rounded-xl"
          >
            <span className="text-s-semibold">Create account</span>
          </Link>
        </div>
      </header>
      <section className="w-[496px] rounded-xl border border-light-gray bg-white p-12">
        <h1 className="text-text-brand-blue mb-6 text-center font-display text-2xl font-bold">
          {showEmailSent ? 'Check your email' : 'Log in to Ramdam'}
        </h1>
        {showEmailSent ? (
          <div>
            <p className="text-text-brand-blue mb-5 text-center text-s-regular ">
              We have sent a temporary verification link. Please check your inbox at{' '}
              <span className="text-s-semibold">{fields.email.value}</span>.
            </p>
            <p className="text-text-brand-blue text-center text-s-regular">
              Not seeing the email?{' '}
              <button
                type="submit"
                className={`${cooldown > 0 ? 'cursor-not-allowed text-blue-200' : 'text-s-semibold text-blue-600'}`}
                onClick={handleResend}
                disabled={cooldown > 0}
              >
                {cooldown > 0 ? `Resend in ${cooldown}secs` : 'Resend'}
              </button>
            </p>
          </div>
        ) : (
          <Form method="post" {...getFormProps(form)} className="space-y-4">
            {alertMessage && (
              <Alert variant="error" className="mb-4">
                {alertMessage}
              </Alert>
            )}
            {form.errors?.length ? (
              <Alert variant="error" id={form.errorId}>
                {form.errors}
              </Alert>
            ) : null}

            {fields.offer.errors?.length ? (
              <Alert variant="error" id={fields.offer.errorId}>
                {fields.offer.errors}
              </Alert>
            ) : null}

            <input {...getInputProps(fields.offer, { type: 'hidden' })} />

            <Control
              required
              id="email"
              label="Business email address"
              error={fields.email.errors}
              labelClassName="pl-1"
            >
              <Input
                {...getInputProps(fields.email, { type: 'email' })}
                autoComplete="email"
                placeholder="john@acme.com"
                variant="registration"
                className="mb-1"
              />
            </Control>
            <Button
              type="submit"
              loading={isLoading}
              disabled={isLoading}
              variant="registration"
              size="xl"
              className="w-full !rounded-xl"
            >
              Continue
            </Button>
          </Form>
        )}
      </section>
      {showEmailSent ? (
        <Button type="button" variant="ghost" onClick={handleGoBack} className="mt-4">
          <span className="text-s-semibold text-blue-600">Go back</span>
        </Button>
      ) : null}
    </main>
  );
}
